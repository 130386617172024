import React from "react"

import { Col, Form } from "react-bootstrap"

function TransTypeField(props) {
    return (
        <>
            {/* Property transType Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="transType">
                        <span className="text-semi-bold text-sm">
                            Valuation type
                        </span>
                    </Form.Label>

                    <div>
                        <Form.Check
                            inline
                            label="Sales"
                            value="Sales"
                            type="radio"
                            checked={
                                props.formik.values.transType ===
                                "Sales Valuation"
                            }
                            onChange={(e) =>
                                props.formik.setFieldValue(
                                    "transType",
                                    "Sales Valuation",
                                )
                            }
                        />
                        <Form.Check
                            inline
                            label="Lettings"
                            value="Lettings"
                            type="radio"
                            checked={
                                props.formik.values.transType ===
                                "Lettings Valuation"
                            }
                            onChange={(e) =>
                                props.formik.setFieldValue(
                                    "transType",
                                    "Lettings Valuation",
                                )
                            }
                        />
                        <Form.Check
                            inline
                            label="Both"
                            value="Both"
                            type="radio"
                            checked={
                                props.formik.values.transType ===
                                "Sales and Lettings Valuation"
                            }
                            onChange={(e) =>
                                props.formik.setFieldValue(
                                    "transType",
                                    "Sales and Lettings Valuation",
                                )
                            }
                        />
                    </div>

                    {props.formik.errors.transType &&
                    props.formik.touched.transType ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.transType}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* Property transType Field End */}
        </>
    )
}

export default TransTypeField
