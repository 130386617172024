import React from "react"
import Image from "next/image"
import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function AddressField(props) {
    return (
        <>
            {/* User Name Field */}
            <Col xs={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="address">
                        <span className="text-semi-bold text-sm">Address</span>
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                        <InputGroup.Text
                            className=" p-0"
                            style={{
                                width: "2.5rem",
                            }}
                        >
                            <Image
                                src="https://webbjenkinsstatic.imgix.net/icons/house.svg"
                                alt="House icon"
                                height={16}
                                width={40}
                            />
                        </InputGroup.Text>
                        <Field
                            id="address"
                            name="address"
                            className="form-control"
                            aria-label="address"
                            type="text"
                        />
                    </InputGroup>
                    {props.formik.errors.address &&
                    props.formik.touched.address ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.address}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* User Name Field End */}
        </>
    )
}

export default AddressField
