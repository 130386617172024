import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Formik } from "formik"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Row } from "react-bootstrap"

import { setValuationDataOne } from "@/slices/valuationModalSlice"

import TransTypeField from "@/fields/transTypeField"
import AddressField from "@/fields/addressField"
import PostcodeField from "@/fields/postcodeField"
import NextButton from "@/fields/nextButton"

function BookValuationStepOne(props) {
    const dispatch = useDispatch()

    // The selector to retrieve the book valuation form from state.
    // const valuationDataDetails = useSelector((state) => state.bookValuationData)
    // const { formData } = valuationDataDetails
    const { formData } = useSelector((state) => state.valuationModal)

    // The schema used for validation of the request viewing form input.
    const BookValuationSchema = Yup.object().shape({
        address: Yup.string().required("This field is required."),
        postcode: Yup.string().required("This field is required."),

        transType: Yup.string()
            .oneOf(
                [
                    "Sales Valuation",
                    "Lettings Valuation",
                    "Sales and Lettings Valuation",
                ],
                "Must be one of 'Sales Valuation', 'Lettings Valuation', or 'Sales and Lettings Valuation'.",
            )
            .required("This field is required."),
    })

    return (
        <>
            <Formik
                initialValues={formData}
                validationSchema={BookValuationSchema}
                // Send the form data to the backend
                onSubmit={async (values) => {
                    dispatch(setValuationDataOne(values))
                }}
            >
                {(formik) => (
                    <FormikForm>
                        <Row>
                            <TransTypeField formik={formik} />
                        </Row>

                        <Row>
                            <AddressField formik={formik} />
                        </Row>
                        <Row>
                            <PostcodeField formik={formik} />
                        </Row>

                        <NextButton formik={formik} />
                    </FormikForm>
                    // Book Valuation Form End
                )}
            </Formik>
        </>
    )
}

export default BookValuationStepOne
