import React from "react"
import Image from "next/image"
import { useDispatch, useSelector } from "react-redux"

import { Formik } from "formik"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Row } from "react-bootstrap"

import NameField from "@/fields/nameField"
import EmailField from "@/fields/emailField"
import PhoneField from "@/fields/phoneField"
import Recaptcha from "@/fields/recaptcha"
import SubmitButton from "@/fields/submitButton"

import {
    setValuationDataTwo,
    sendValuationData,
} from "@/slices/valuationModalSlice"

function BookValuationStepTwo(props) {
    const dispatch = useDispatch()

    // The selector to retrieve the book valuation form from state.
    // The selector to retrieve the book valuation form from state.
    const { status, formData } = useSelector((state) => state.valuationModal)

    // A regular expression that can detect a small number of fake mobile
    // numbers that will be used for validation (doesn't allow chars).
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // The schema used for validation of the request viewing form input.
    const BookValuationSchema = Yup.object().shape({
        fullName: Yup.string().required("This field is required."),
        email: Yup.string()
            .email("Must be a valid email.")
            .required("This field is required."),
        phone: Yup.string().matches(
            phoneRegExp,
            "Must be a valid phone number (digits only).",
        ),
        captcha: Yup.boolean().oneOf(
            [true],
            "Please confirm you are not a robot.",
        ),
    })
    return (
        <>
            <Formik
                initialValues={formData}
                validationSchema={BookValuationSchema}
                // Send the form data to the backend
                onSubmit={async (values) => {
                    await dispatch(sendValuationData(values))
                }}
            >
                {(formik) => (
                    <FormikForm>
                        <Row>
                            <NameField formik={formik} />

                            <EmailField formik={formik} />

                            <PhoneField formik={formik} />
                        </Row>
                        <div className="d-none d-sm-inline">
                            <Recaptcha formik={formik} size="normal" />
                        </div>
                        <div className="d-sm-none">
                            <Recaptcha formik={formik} size="compact" />
                        </div>

                        <SubmitButton
                            submit_message="Book valuation"
                            status={status}
                            formik={formik}
                        />
                        <a
                            href="#"
                            className="ms-auto my-auto d-flex p-0"
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(setValuationDataTwo(formik.values))
                            }}
                        >
                            <Image
                                src="https://webbjenkinsstatic.imgix.net/icons/back-arrow.svg"
                                alt="Back arrow"
                                height={12}
                                width={30}
                                className="me-1"
                            />
                            Back to previous step
                        </a>
                    </FormikForm>
                    // Book Valuation Form End
                )}
            </Formik>
        </>
    )
}

export default BookValuationStepTwo
