import React from "react"
import dynamic from "next/dynamic"
import { useSelector, useDispatch } from "react-redux"

import { Modal } from "react-bootstrap"

import * as ga from "@/ga"

import {
    toggleValuationModal,
    resetValuationModal,
} from "@/slices/valuationModalSlice"

import BookValuationStepOne from "@/forms/bookValuationStepOne"
import BookValuationStepTwo from "@/forms/bookValuationStepTwo"

const SuccessToast = dynamic(() => import("@/toasts/successToast"))
const ErrorToast = dynamic(() => import("@/toasts/errorToast"))

function BookValuationModal() {
    const dispatch = useDispatch()

    const { cookieConsent } = useSelector((state) => state.cookie)

    const generateLead = () => {
        if (cookieConsent === "true") {
            ga.event({
                action: "generate_lead",
            })
        }
    }

    // The selector to retrieve the book valuation form status from state.
    // The selector to retrieve the book valuation form position from state.
    // The selector to retrieve whether the viewing modal should be
    // shown or hidden from state.
    const { showValuationModal, formPosition, status } = useSelector(
        (state) => state.valuationModal,
    )

    return (
        <>
            <SuccessToast
                status={status}
                resetModal={() => {
                    dispatch(resetValuationModal())
                    generateLead()
                }}
            />
            <ErrorToast
                status={status}
                resetModal={() => dispatch(resetValuationModal())}
            />
            {/* Book Valuation Form Modal */}
            <Modal
                show={showValuationModal}
                onHide={() => dispatch(toggleValuationModal(false))}
                fullscreen="lg"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        <h2 className="text-center">Book valuation</h2>
                        <p className="text-center">
                            Simply enter your details and a member of our team
                            will be in touch shortly to arrange your valuation.
                        </p>
                    </Modal.Title>

                    {/* Book Valuation Form */}
                    {formPosition ? (
                        <>
                            <h3 className="mt-4 text-end  me-2 step-count">
                                Step 2 of 2
                            </h3>
                            <hr className="mb-2" />
                            <BookValuationStepTwo />
                        </>
                    ) : (
                        <>
                            <h3 className="mt-4 text-end me-2 step-count">
                                Step 1 of 2
                            </h3>
                            <hr className="mb-2" />
                            <BookValuationStepOne />
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {/* Book Valuation Form Modal End */}
        </>
    )
}

export default BookValuationModal
