import React from "react"
import Image from "next/image"
import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function PostcodeField(props) {
    return (
        <>
            {/* User Name Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="postcode">
                        <span className="text-semi-bold text-sm">Postcode</span>
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                        <InputGroup.Text
                            className=" p-0"
                            style={{
                                width: "2.5rem",
                            }}
                        >
                            <Image
                                src="https://webbjenkinsstatic.imgix.net/icons/postcode.svg"
                                alt="Location icon to resemble postcode"
                                height={16}
                                width={40}
                            />
                        </InputGroup.Text>
                        <Field
                            id="postcode"
                            name="postcode"
                            className="form-control"
                            aria-label="postcode"
                            type="text"
                        />
                    </InputGroup>
                    {props.formik.errors.postcode &&
                    props.formik.touched.postcode ? (
                        <p className="text-danger text-normal text-sm">
                            {props.formik.errors.postcode}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* User Name Field End */}
        </>
    )
}

export default PostcodeField
